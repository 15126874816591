






























































import { Component, Prop, Vue } from 'vue-property-decorator';
import Company from '@/models/Company';
import { namespace } from 'vuex-class';
import { companyStoreActions, companyStoreMutations } from '@/store/company.store';
import CompanyRepository from '@/api/repositories/CompanyRepository';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import { UserRoles } from '@/enum/UserRoles';

const CompanyStore = namespace('company');
const companyRepository: CompanyRepository = RepositoryFactory.get('company');

@Component({
    components: {
        EditCompanyComponent: () => import(
            /* webpackChunkName: "EditCompanyComponent" */
            '@/components/EditCompany.component.vue')
    }
})
export default class CompanyTableComponent extends Vue {

    @CompanyStore.Action(companyStoreActions.DELETE)
    public deleteCompanyAction!: (payload: { company: Company }) => Promise<Company>;

    @CompanyStore.Action(companyStoreActions.LIST)
    public loadAllCompanies!: (payload: { withDeleted: boolean }) => Promise<Company[]>;

    @CompanyStore.State('company')
    public company!: Company;

    @CompanyStore.Mutation(companyStoreMutations.SAVE_COMPANY)
    public saveCompany!: (company: Company | null) => void;

    @Prop({ default: () => [] })
    public companies!: Company[];

    @Prop({ default: '' })
    public searchValue!: string;

    public showEditDialog: boolean = false;
    public showDeleteDialog: boolean = false;

    /**
     * sorts the list of items, currently the list is only sorted by status
     * @param items
     * @param index
     * @param isDescending
     * @private
     */
    private customSort(items: any[], index: string[], isDescending: boolean[]) {
        items.sort((a: Company, b: Company) => {
            if (index[0] === 'status') {
                if (isDescending[0]) {
                    return a.status ? 1 : -1;
                } else {
                    return a.status ? -1 : 1;
                }
            } else if (index[0] === 'name') {
                if (isDescending[0]) {
                    return b.name.localeCompare(a.name);
                } else {
                    return a.name.localeCompare(b.name);
                }
            }

            return 1;
        });

        return items;
    }

    public tableHeaders = [{
        text: this.$t('COMPANY.TABLE.NAME'),
        value: 'name',
        sortable: true
    }, {
        text: this.$t('COMPANY.TABLE.ADDRESS'),
        value: 'address',
        sortable: false
    }, {
        text: this.$t('COMPANY.TABLE.PHONE'),
        value: 'phone',
        sortable: false
    }, {
        text: this.$t('COMPANY.TABLE.EMAIL'),
        value: 'email',
        sortable: false
    }, {
        text: this.$t('COMPANY.TABLE.STATUS'),
        value: 'status',
        sortable: true
    }, {
        text: this.$t('COMPANY.TABLE.OPTIONS'),
        value: 'options',
        sortable: false,
        align: 'right'
    }];

    public rowSelected(company: Company) {
        this.$emit('rowSelected', company);
    }

    public editSelected(company: Company) {
        this.saveCompany(company);
        this.showEditDialog = true;
    }

    public deleteSelected(company: Company) {
        this.saveCompany(company);
        this.showDeleteDialog = true;
    }

    public async recycleSelected(company: Company) {
        await companyRepository.recycle({ id: company.id });
        await this.loadAllCompanies({ withDeleted: true });
    }

    public async resendInvite(company: Company) {
        try {
            await companyRepository.resendInvite({ id: company.id });
            this.$notifySuccessSimplified('TOASTS.RESEND_INVITATION.SUCCESS');
        } catch (e) {
            this.$notifyErrorSimplified('TOASTS.RESEND_INVITATION.ERROR');
        }
    }

    public async closeDialog(reload: boolean) {
        if (reload) {
            await this.loadAllCompanies({ withDeleted: true });
        }

        this.showEditDialog = false;
        this.saveCompany(null);
    }

    public deleteConfirmed() {
        this.deleteCompanyAction({ company: this.company });
        this.showDeleteDialog = false;
        this.loadAllCompanies({ withDeleted: true });
    }

    public closeDeleteDialog() {
        this.saveCompany(null);
        this.showDeleteDialog = false;
    }

    private get isAdmin() {
        return this.$hasRole(UserRoles.ADMIN);
    }
}

