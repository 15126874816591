var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-table"},[_c('v-card',{staticClass:"box-standard-shadow table-container py-1"},[_c('v-data-table',{staticClass:"row-pointer",attrs:{"items":_vm.companies,"headers":_vm.tableHeaders,"search":_vm.searchValue,"custom-sort":_vm.customSort,"items-per-page":10},on:{"click:row":_vm.rowSelected},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(!item.isDeleted)?_c('span',[_vm._v(" "+_vm._s(item.status ? _vm.$t('COMPANY.TABLE.ACTIVE') : _vm.$t('COMPANY.TABLE.INACTIVE'))+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t('COMPANY.TABLE.DELETED')))])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.street)+", "+_vm._s(item.city))])]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[(item.manager && !item.manager.hasSetPassword)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.resendInvite(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-envelope")])],1)]}}],null,true)},[_c('span',[_vm._v("Resend invitation")])]):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.editSelected(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])],1),(!item.isDeleted && _vm.isAdmin)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteSelected(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-trash")])],1):_vm._e(),(item.isDeleted && _vm.isAdmin)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.recycleSelected(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-recycle")])],1):_vm._e()],1)]}}])})],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.showEditDialog),callback:function ($$v) {_vm.showEditDialog=$$v},expression:"showEditDialog"}},[_c('edit-company-component',{attrs:{"company":_vm.company},on:{"closeDialog":_vm.closeDialog}})],1),_c('confirm-dialog-component',{attrs:{"showDialog":_vm.showDeleteDialog},on:{"confirmed":_vm.deleteConfirmed,"closed":_vm.closeDeleteDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }